<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">People</h2>
    </template>
    <template #page-content>
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        label-position="bottom"
        mobile-mode="minimalist"
      >
        <b-step-item
          icon="google-circles-communities"
          label="General Information"
          :clickable="isStepsClickable"
        >
          <ValidationObserver ref="form1" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitGeneralInformation)">
              <general-information-component
                :props-data="data"
                :emails="data.allWorkEmails"
                :all-emails="allEmails"
                :list-timezones="listTimezones"
                @on-input-typed="setDataStep1"
                @on-select-email="onSelectEmail"
              ></general-information-component>
            </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item
          icon="account-supervisor-circle"
          label="Professional Information"
        ></b-step-item>
        <b-step-item icon="file-document" label="Summary"></b-step-item>
        <template #navigation="{ previous, next }">
          <div class="level step-footer">
            <div class="level-right">
              <b-button
                type="is-disabled"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Back
              </b-button>
            </div>
            <div class="level-right">
              <b-button
                type="is-primary"
                :disabled="isDisabled"
                @click.prevent="saveAndNext(next)"
              >
                Save & Next
              </b-button>
            </div>
          </div>
        </template>
      </b-steps>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import moment from 'moment'
import { showToast } from '@/services/util'
import GeneralInformationComponent from '../../components/GeneralInformationComponent.vue'

export default {
  components: { GeneralInformationComponent },
  data() {
    const today = new Date()
    return {
      data: {
        id: '',
        ktp: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        nick_name: '',
        allWorkEmails: null,
        work_email: null,
        personal_email: '',
        personal_phone: '',
        place_of_birth: '',
        date_of_birth: null,
        nationality: '',
        gender: null,
        blood_type: null,
        religion: null,
        address: '',
        rt: '',
        rw: '',
        district: '',
        village: '',
        postal_code: '',
        city: '',
        province: '',
        timezone: null,
        isAdmin: 0,
      },
      isDisabled: false,
      allEmails: null,
      activeStep: 0,
      isStepsClickable: true,
      idEmail: null,
      selectedReportedToInfo: null,
      listTimezones: moment.tz.names(),
      minDate: new Date(
        today.getFullYear() - 100,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    }
  },
  async mounted() {
    await this.getOfficeEmail()
  },
  methods: {
    setDataStep1(val) {
      this.data = { ...val }
    },
    saveAndNext(next) {
      this.isDisabled = true
      if (this.activeStep === 0) {
        this.$refs.form1.validate().then(async (success) => {
          if (!success) {
            console.log('ERROR!')
          } else {
            await this.submitGeneralInformation().then((response) => {
              let url =
                '/employment/edit/' + response.data.data.id + '?create=true'
              this.$router.push(url)
            })
          }
        })
      } else if (this.activeStep === 1) {
        next.action()
      } else if (this.activeStep === 2) {
        next.action()
      }

      this.isDisabled = false
    },
    getOfficeEmail() {
      ApiService.get(
        '/api/space-roketin/employee/registration-from-spaceid?sort_field=users.id&sort_order=ASC'
      ).then((response) => {
        this.data.allWorkEmails = response.data.data.map((we) => we.workEmail)
        this.allEmails = response.data.data
      })
    },
    onSelectEmail(idEmail) {
      this.idEmail = idEmail
    },
    submitGeneralInformation() {
      return new Promise((resolve, reject) => {
        let params = {
          ktp: this.data.ktp,
          firstName: this.data.first_name,
          middleName: this.data.middle_name,
          lastName: this.data.last_name,
          nickName: this.data.nick_name,
          workEmail: this.data.work_email,
          personalEmail: this.data.personal_email.toLowerCase(),
          personalPhone: this.data.personal_phone,
          placeOfBirth: this.data.place_of_birth,
          dateOfBirth: moment(this.data.date_of_birth).format('YYYY-MM-DD'),
          nationality: this.data.nationality,
          gender: this.data.gender,
          bloodType: this.data.blood_type,
          religion: this.data.religion,
          address: this.data.address,
          rt: this.data.rt,
          rw: this.data.rw,
          district: this.data.district,
          village: this.data.village,
          postalCode: this.data.postal_code,
          city: this.data.city,
          province: this.data.province,
          timezone: this.data.timezone,
          isAdmin: this.data.is_admin,
        }

        ApiService.post(`/api/space-roketin/user/store`, params).then(
          (response) => {
            this.data = {
              ktp: '',
              first_name: '',
              middle_name: '',
              last_name: '',
              nick_name: '',
              allWorkEmails: null,
              personal_email: '',
              personal_phone: '',
              place_of_birth: '',
              date_of_birth: null,
              nationality: '',
              gender: null,
              blood_type: null,
              religion: null,
              address: '',
              work_email: '',
              rt: '',
              rw: '',
              district: '',
              village: '',
              postal_code: '',
              city: '',
              province: '',
              timezone: '',
              is_admin: 0,
            }

            resolve(response)
            showToast('Add Success', 'is-success', 'is-top')
          },
          (error) => {
            reject(error)
            showToast(error.response.meta.message, 'is-danger', 'is-top')
          }
        )
      })
    },
  },
}
</script>
